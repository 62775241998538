import React, { useState } from "react";
import css from 'styled-jsx/css';
import { Button, Form, FieldGroup, AlphanumericInput } from "@lmig/lmds-react";
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import Router from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import LinkEnvironmentalize from "./LinkEnvironmentalize";

const Hero = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(
      object().shape({
        query: string()
          .required('Must enter a valid ZIP Code')
          .matches(/^\d{5}$/, 'Must enter a valid ZIP Code'),
      }),
    ),
  });
  const [curVal, setCurVal] = useState(undefined);

  const submitSearch = () => {
    const passedQuery = { query: curVal, location: true, radius: '25' };
    const params = new URLSearchParams(window?.location?.search);
    const passedQueryParams = new URLSearchParams(passedQuery);

    passedQueryParams.forEach((value, name) => {
      params.set(name, value);
    });

    Router.push(
      {
        pathname: '/insurance-agent',
        passedQuery,
      },
      `/insurance-agent?${params.toString()}`,
    );
  };

  const onAlphaChange = (curSearch) => {
    setValue('query', curSearch);
    setCurVal(curSearch);
    clearErrors();
  };

  const validationMessages = Object.entries(errors).map(([, { message }]) => ({ type: 'error', text: message }));

  const { className: headingClass, styles: headingStyles } = css.resolve`
    @import './global-styles/tokens';

    .lm-Heading {
      color: #1a1446;
      max-width: 15.75rem;
      margin: 3rem auto 0.5rem;
      font-size: 1.75rem;
      @media screen and (min-width: $lm-breakpoint-md-min) {
        max-width: unset;
        font-size: 2.25rem;
        margin: 2rem 0 1rem;
        @media screen and (min-width: $lm-breakpoint-lg-min) {
          font-size: 2.5rem;
          margin-top: 5rem;
        }
      }
    }
  `;

  return (
    <div id="heroWrapper">
      <div className="textSection">
        <Heading type="h2-light" tag="h1" className={headingClass} mobileHeadingCentered>
          Insurance agents&nbsp;<br /><b style={{ fontWeight: 700 }}>near you</b>
        </Heading>
        <h2 className="heroSubtitle">Expert advice from an independent insurance agent <b style={{ fontWeight: 700 }}>in your community.</b></h2>
        <Form id="find-an-agent-search" data-testid="search-find-an-agent-form" onSubmit={handleSubmit(submitSearch)}>
          <FieldGroup messages={validationMessages} className="formFieldGroup">
            <div className="formContent">
              <AlphanumericInput
                labelVisual="ZIP Code"
                onChange={(e) => onAlphaChange(e.target.value)}
                className="searchBar"
                value={curVal}
                data-testid="find-agent-search-bar"
                id="find-agent-search-bar"
                filter={/^[0-9]/i}
                type="tel"
                mask="zipCode"
                maxLength={5}
              />
              <Button size="small" className="searchButton" id="find-an-agent-search-button" type="submit" data-testid="search-agent-submit" variant="primary">Find an agent</Button>
            </div>
          </FieldGroup>
        </Form>
        <div className="orSection">
          <div className="orWord">- or -</div>
          <LinkEnvironmentalize attribution>
            <Button href="/quote" variant="secondary" size="small" className="startQuote" data-testid="startQuote">
              Start your quote
            </Button>
          </LinkEnvironmentalize>
        </div>
      </div>
      <div className="imageSection">
        <Image imageDomain="IMAGE" preload>
          <img
            className="heroImage"
            src="comparion/Homepage_Hero.jpg?height=931&width=1396"
            alt="Couple signing insurance documents"
            width="698"
            height="466"
          />
        </Image>
      </div>
      <style global jsx>{`
        @import './Hero.scoped.scss';
        @import './global-styles/tokens';
        #find-an-agent-search{
          display: flex;
          justify-content: center;

          .lm-FieldGroup.formFieldGroup {
            width: 100%;
            margin-top: unset;

            .lm-FieldGroupMessages.has-messages.formFieldGroup-messages {
              margin-top: -0.8rem;
            }
          }

          .formContent {
            display: flex;
            flex-flow: column;

            @media screen and (min-width: $lm-breakpoint-md-min) {
              flex-flow: row;
            }
          }

          .lmig-Button.searchButton {
            height: 3rem;
            width: 100%;
            max-width: unset;
            margin-left: unset;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              max-width: 10.25rem;
              margin-left: 1rem;
            }
            @media screen and (min-width: $lm-breakpoint-lg-min) {
              max-width: 11.25rem;
            }
          }
        }
        #heroWrapper{
          .orSection{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .orWord{
            margin: 0.75rem 0 0.75rem 0;
            font-weight: 700;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              margin: 0.5rem 0 0.75rem 0;
            }
          }
          .lmig-Button.startQuote{
            width: 100%;
            height: 3.125rem;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 37.5rem;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              max-width: 24rem;
            }
          }
        }
        `}</style>
      {headingStyles}
    </div>
  );
};

export default Hero;
