import React from "react";
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import Heading from "@lmig/dotcom-aspect-components/Utility/Heading";

const TextBubble = ({
  children,
  tail = 'left',
  color = 'teal',
  secondaryText = null
}) => {
  const colorings = {
    gray: {
      primary: '#f5f5f5',
      shadow: '#c9c8c9'
    },
    teal: {
      primary: '#f2fcfc',
      shadow: '#99e5ea'
    }
  };

  return (
    <div className="textBubble">
      <div className={`bubble ${tail}`}>
        {children}
      </div>
      {tail ? (<>
        <div className={`spike ${tail}`} />
        <div className={`spike shadow ${tail}`} />
      </>) : null}
      {secondaryText &&
        <div>{secondaryText}</div>
      }
      <style jsx>{`
      @import './global-styles/tokens';

        .bubble {
          background: ${colorings[color].primary};
          width: fit-content;
          border-radius: 0.75rem;
          padding: 2rem 1.5rem;
          margin-left: 0.75rem;
          box-shadow: -0.825rem 1rem ${colorings[color].shadow};
          @media screen and (min-width: $lm-breakpoint-md-min) {
            padding: 3.25rem 2.5rem;
            margin-left: 1.75rem;
            box-shadow: -1.75rem 1.25rem ${colorings[color].shadow};
          }
          &.right {
            box-shadow: 0.825rem 1rem ${colorings[color].shadow};
            margin-right: 0.75rem;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              padding: 1rem;
              margin-right: 1.75rem;
              box-shadow: 1.75rem 1.25rem ${colorings[color].shadow};
              @media screen and (min-width: 64rem) {
                margin-left: 0;
              }
            }
          }
        }
        .spike {
          position: relative;
          width: 3.625rem; 
          height: 2.5rem;
          left: 2.25rem;
          top: -1px; 
          background: ${colorings[color].primary};
          clip-path: polygon(20% 0, 100% 0, 0 100%);
          z-index: 2;
          @media screen and (min-width: $lm-breakpoint-md-min) {
            left: 3.5rem;
          }
          &.shadow {
            background: ${colorings[color].shadow};
            top: -1.5625rem;
            left: 1.25rem;
            z-index: 1;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              left: 2.5rem;
            }
          }
          &.right {
            transform: scaleX(-1);
            left: calc(min(100vw, 40.5rem) - 10.5rem);
            @media screen and (min-width: $lm-breakpoint-md-min) {
              left: 28rem;
              @media screen and (min-width: 64rem) {
                left: 27rem;
                @media screen and (min-width: $lm-breakpoint-xl-min) {
                  left: 30rem;
                }
              }
            }
            &.shadow {
              left: calc(min(100vw, 40.5rem) - 9.5rem);
              @media screen and (min-width: $lm-breakpoint-md-min) {
                left: 29rem;
                @media screen and (min-width: 64rem) {
                  left: 28rem;
                  @media screen and (min-width: $lm-breakpoint-xl-min) {
                    left: 31rem;
                  }
                }
              }
            }
          }
        }
        .textBubble {
          max-width: 40.5rem;
          &:has(.left) {
            margin: 0 auto;
            @media screen and (min-width: $lm-breakpoint-md-min) {
              margin-left: 0;
              flex-basis: 100%;
            }
          }
          @media screen and (min-width: 64rem) {
            &:has(.right) {
              margin-top: 7rem;
            }
          }
          @media screen and (min-width: $lm-breakpoint-xl-min) {
            flex: 0 0 25rem;
            &:has(.left) {
              margin-right: 0;
              flex-basis: auto;
            }
            &:has(.right) {
              flex: 1 0 40rem;
            }
          }
        }
      `}</style>
    </div>
  );
};

TextBubble.prototypes = {
  children: PropTypes.node.isRequired,
  tail: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['teal', 'gray']),
  secondaryText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
};

const TextBubbles = ({
  heading,
  answerText,
  secondaryText = null
}) => {
  const { className: headingClass, styles: headingStyles } = css.resolve`
    @import './global-styles/tokens';

    .lm-Heading {
      margin: 0;
      max-width: 15.5rem;
      @media screen and (min-width: $lm-breakpoint-sm-min) {
        max-width: 17.5rem;
      }
    }
  `;

  return (
    <div className="textBubbles">
      <TextBubble color="teal" tail="left">
        <Heading tag="h2" type="h3" className={headingClass}>
          {heading}
        </Heading>
      </TextBubble>
      <TextBubble color="gray" tail="right" secondaryText={secondaryText}>
        {answerText}
      </TextBubble>
      <style jsx>{`@import './TextBubbles.scoped.scss';`}</style>
      {headingStyles}
    </div>
  );
};

TextBubbles.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  answerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TextBubbles;