import React from "react";
import PropTypes from 'prop-types';
import { Link, Button } from "@lmig/lmds-react";
import LinkEnvironmentalize from "@lmig/dotcom-aspect-components/LinkEnvironmentalize";
import Heading from "@lmig/dotcom-aspect-components/Utility/Heading";
import Image from "@lmig/dotcom-aspect-components/Utility/Image";
import LmdsIcon from "@lmig/dotcom-aspect-components/Icon/LmdsIcons";

const WhyShouldI = ({
  additionalClass = 'homepage',
  imageSrc,
  imageAlt,
  heading,
  subHeading,
  cardsTeal,
  textSections,
  buttonText,
  buttonVariant = 'secondary',
  buttonDomain = 'COMPARION',
  buttonHref
}) => (
  <div className={additionalClass}>
    <div className="whyComparion">
      {!!imageSrc && !!cardsTeal && <div className="imageCardsWrapper">
        <Image imageDomain="IMAGE" preload>
          <img
            className="whyChooseImage"
            src={imageSrc}
            alt={imageAlt}
            width="544px"
            height="357px"
          />
        </Image>
        <div className="cardsTeal">
          {cardsTeal.map(text => {
            const firstWord = text.substring(0, text.indexOf(' '));
            const remainingText = text.substring(text.indexOf(' '));

            return (
              <div className="cardTeal" key={text}>
                <span className="firstWord">{firstWord}</span>
                <span className="remainingText">{remainingText}</span>
              </div>
            );
          })}
        </div>
      </div>
      }
      <div>
        <div className="headingSection">
          <Heading align="center" tag="h2" type="h3" className="mainTitle">{heading}</Heading>
          <div className="subHeading">{subHeading}</div>
        </div>
        <div className="whyChooseContent">
          <div className="textSections">
            {textSections.map(({ icon, title, text, linkText, linkHref, linkDomain = "COMPARION" }) => (
              <div className="textSection" key={title}>
                <div className="iconWrapper">
                  <LmdsIcon icon={icon} size={{ base: '24', md: '32' }} />
                </div>
                <div className="textWrapper">
                  <h3 className="whyComparionSubtitle"><p className="whyComparionSubtitle">{title}</p></h3>
                  <p className="whyComparionText">{text}</p>
                  {!!linkHref && (
                    <LinkEnvironmentalize domain={linkDomain}>
                      <Link href={linkHref} variant="standalone" className="textLink">
                        {linkText}
                      </Link>
                    </LinkEnvironmentalize>
                  )}
                </div>
              </div>
            ))}
            {!!buttonHref && (
              <LinkEnvironmentalize domain={buttonDomain} attribution>
                <Button variant={buttonVariant} href={buttonHref} className="whyShouldIButton">
                  {buttonText}
                </Button>
              </LinkEnvironmentalize>
            )}
          </div>
        </div>
      </div>
    </div>
    <style global jsx>{`@import './WhyShouldI.scoped.scss';`}</style>
  </div>
);

WhyShouldI.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  additionalClass: PropTypes.oneOf(['homepage', 'product']),
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  cardsTeal: PropTypes.arrayOf(PropTypes.string),
  textSections: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    linkText: PropTypes.string,
    linkHref: PropTypes.string,
  })).isRequired,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonDomain: PropTypes.string,
  buttonHref: PropTypes.string,
};

export default WhyShouldI;