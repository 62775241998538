import React from "react";
import dynamic from 'next/dynamic';
import ComponentMapRender from "@lmig/dotcom-aspect-components/Utility/ComponentMapRender";
import Heading from "@lmig/dotcom-aspect-components/Utility/Heading";
import RichText from "@lmig/dotcom-aspect-comparion-components/RichText";
import withPreprocessedContent from "@lmig/dotcom-aspect-comparion-components/Utility/withPreprocessedContent";
import Head from "@lmig/dotcom-aspect-comparion-components/Head";
import Layout from "@lmig/dotcom-aspect-comparion-components/Layout";
import Hero from "@lmig/dotcom-aspect-comparion-components/Hero";
import LazyLoad from "@lmig/dotcom-aspect-comparion-components/LazyLoad";
import TextBubbles from "@lmig/dotcom-aspect-comparion-components/TextBubbles";
import { getServerSideProps } from "@lmig/dotcom-aspect-comparion-helpers/staticPageController";
import WhyShouldI from "@lmig/dotcom-aspect-comparion-components/WhyShouldI";
import InsuranceProducts from "@lmig/dotcom-aspect-comparion-components/InsuranceProducts";
import WrapperSizeable from "@lmig/dotcom-aspect-comparion-components/Utility/WrapperSizeable";

const InsurancePartners = dynamic(() => import("@lmig/dotcom-aspect-comparion-components/InsurancePartners"));

const componentMap = {
  insurancePartners: ({ ...rest }) => <LazyLoad><InsurancePartners {...rest} /></LazyLoad>,
  heading: Heading,
  insuranceProducts: InsuranceProducts,
  richText: RichText,
  textBubbles: TextBubbles,
  whyShouldI: WhyShouldI,
  wrapperSizeable: WrapperSizeable
};

const Homepage = withPreprocessedContent(({ content: { mainContent = [], meta = {} } }) => (
  <Layout>
    <Head {...meta} />
    <Hero />
    <ComponentMapRender componentMap={componentMap} content={mainContent} />
  </Layout>
));

export { getServerSideProps };

export default Homepage;